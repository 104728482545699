(function($) {


  /************************************** Match Heights plugin */ 
  if($('.match-height').length > 0) {
    $('.match-height').matchHeight();
  }

  /************************************** Mobile Navigation */ 
  $(".mobile-navigation ul").html($("#menu-main-menu").html());
  $(".nav-trigger").click(function(){
      if ($(".mobile-navigation").hasClass("expanded")) {
          $(".mobile-navigation.expanded").removeClass("expanded");
          $(this).removeClass("open");
      } else {
          $(".mobile-navigation").addClass("expanded");
          $(this).addClass("open");
      }
  }); 

})(jQuery); // Fully reference jQuery after this point.
